var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 col-md-10 col-xl-9 mx-auto" },
    [
      _c(
        "v-row",
        _vm._l(_vm.autoRepairServices, function (service, index) {
          return _c(
            "v-col",
            { key: index, staticClass: "col-12 col-md-4 mt-16" },
            [
              _c(
                "div",
                [
                  _c("v-img", {
                    staticClass: "mx-auto mb-6",
                    attrs: {
                      width: "124",
                      height: "118",
                      src: require("../../assets/images/cities/" +
                        _vm.imageFolder +
                        "/Icons/" +
                        service.icon),
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center font-weight-bold mb-3 auto-repair-service-title",
                    },
                    [_vm._v(_vm._s(service.title))]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center mb-3 auto-repair-service-description",
                      staticStyle: { height: "108px" },
                    },
                    [_vm._v(_vm._s(service.description))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none white--text auto-estimate-city-hero-btn mobile-btn",
                      attrs: {
                        elevation: "0",
                        height: "48",
                        block: "",
                        color: "#12945F",
                        to: { name: "AutoEstimate" },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("BookNow")) + " ")]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }