var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-11 mx-auto mt-16", attrs: { id: "cityDescription" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col-12 col-md-6" },
            [
              _c("v-img", {
                staticClass: "mt-15",
                attrs: {
                  "max-height": "592",
                  contain: "",
                  src: require("../../assets/images/cities/Dallas/Images/mobile-mechanic-we-come-to-your-location.webp"),
                  alt: "Green line Illustration of UcarMobile van driving to the client’s location",
                },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "col-12 col-md-5" }, [
            _vm.title
              ? _c("h2", {}, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")])
              : _c("h2", {}, [
                  _vm._v(" " + _vm._s(_vm.$t("ConvenientAndTopQuality")) + " "),
                ]),
            _vm.cityLink
              ? _c(
                  "p",
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          rel: "canonical",
                          href: "https://www.plano.gov/",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.cityName))]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .$t("ConvenientAndTopQualityDescription")
                            .toString()
                            .replaceAll("*City*", "")
                        ) +
                        " "
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/about-us", target: "_blank" } },
                      [_vm._v("UcarMobile")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .$t("ConvenientAndTopQualityDescriptionPart2")
                            .toString()
                            .replaceAll("*City*", _vm.cityName)
                        ) +
                        " "
                    ),
                  ],
                  1
                )
              : _c(
                  "p",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .$t("ConvenientAndTopQualityDescription")
                            .toString()
                            .replaceAll("*City*", _vm.cityName)
                        ) +
                        " "
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/about-us", target: "_blank" } },
                      [_vm._v("UcarMobile")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .$t("ConvenientAndTopQualityDescriptionPart2")
                            .toString()
                            .replaceAll("*City*", _vm.cityName)
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm
                      .$t("ConvenientAndTopQualityDescription1")
                      .toString()
                      .replaceAll("*City*", _vm.cityName)
                  ) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-16" },
        [
          _c("v-col", { staticClass: "col-12 col-md-6 order-md-1 order-2" }, [
            _vm.mobileMechanics
              ? _c("h2", [
                  _vm._v(" " + _vm._s(_vm.$t(_vm.mobileMechanics)) + " "),
                ])
              : _c("h2", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("WhyChooseMobileMechanics")) + " "
                  ),
                ]),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm
                      .$t("WhyChooseMobileMechanicsDescription")
                      .toString()
                      .replaceAll("*City*", _vm.cityName)
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("v-col", { staticClass: "col-12 col-md-6 order-md-2 order-1" }, [
            _c(
              "div",
              [
                _c("v-img", {
                  staticStyle: { position: "relative", right: "0px" },
                  attrs: {
                    "max-height": "450",
                    "min-height": "231",
                    contain: "",
                    src: require("../../assets/images/cities/Dallas/Images/ucarmobile-mechanic-brake-pad-replacement.png"),
                    alt: "UcarMobile mechanic taking off the left wheel to replace the brake pad",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }