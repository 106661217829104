var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col-12 mt-16",
      attrs: { id: "popular-autoRepair-service" },
    },
    [
      _vm.title
        ? _c(
            "h2",
            {
              staticClass: "mx-auto text-center",
              staticStyle: { "max-width": "533px" },
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")]
          )
        : _c(
            "h2",
            {
              staticClass: "mx-auto text-center",
              staticStyle: { "max-width": "533px" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm
                      .$t("PopularAutoRepairService.Title")
                      .toString()
                      .replace("*City*", _vm.cityName)
                  ) +
                  " "
              ),
            ]
          ),
      _c("auto-repair-service-card", {
        attrs: {
          "city-name": _vm.cityName,
          "icon-postfix": _vm.iconPostFix,
          "image-folder": _vm.imageFolder,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }