var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-10", attrs: { id: "city-hero" } },
    [
      _c(
        "section",
        { staticClass: "col-12" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "col-xs-12 col-md-6 left-col-city-hero mt-xs-1 order-2 order-md-1",
                },
                [
                  _c(
                    "div",
                    [
                      _vm.title
                        ? _c("h1", { staticClass: "white--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .$t(_vm.title)
                                    .replace("*City*", _vm.cityName)
                                ) +
                                " "
                            ),
                          ])
                        : _c("h1", { staticClass: "white--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .$t("AutoRepairByMobileMechanicsInCity")
                                    .replace("*City*", _vm.cityName)
                                ) +
                                " "
                            ),
                          ]),
                      _vm.subTitle
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "information d-block mt-3 tip-below-button white--text mb-6",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t(_vm.subTitle)) + " ")]
                          )
                        : _c(
                            "span",
                            {
                              staticClass:
                                "information d-block mt-3 tip-below-button white--text mb-6",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("OurExpertMechanics")) + " "
                              ),
                            ]
                          ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "mt-0 white--text auto-estimate-city-hero-btn text-none",
                          staticStyle: { border: "1px solid white !important" },
                          attrs: {
                            color: "transparent",
                            height: "48",
                            elevation: "0",
                            to: { name: "AutoEstimate" },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("GetAnEstimate")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "col-xs-12 col-md-6 order-1 order-md-2 pl-1 pr-0",
                },
                [
                  _c("v-img", {
                    staticClass: "mt-12",
                    attrs: {
                      "lazy-src": require("../../assets/images/cities/" +
                        _vm.imageFolder +
                        "/Images/auto-repair-mechanic-" +
                        _vm.prefix +
                        ".png"),
                      src: require("../../assets/images/cities/" +
                        _vm.imageFolder +
                        "/Images/auto-repair-mechanic-" +
                        _vm.prefix +
                        ".png"),
                      alt: "UcarMobile mechanic at client’s location giving her an estimate on the auto repair services",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass:
            "justify-space-around hidden-xs-only justify-md-space-between pa-5 px-5 px-md-16 background-white",
          attrs: { id: "service-description-rectangle" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../../assets/icons/serviceFeature/hexagon.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("MonthWarranty")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-sm-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../../assets/icons/serviceFeature/triangle.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("CertifiedMechanics")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-md-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../../assets/icons/serviceFeature/square.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("EasyConvenient")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-lg-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../../assets/icons/serviceFeature/circle.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("ReliableAffordable")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.$vuetify.breakpoint.xsOnly
        ? _c(
            "v-layout",
            {
              staticClass:
                "justify-space-around justify-md-space-between pa-5 px-5 px-md-16 background-white",
              attrs: { id: "feature-carousel-description" },
            },
            [
              _c(
                "v-carousel",
                {
                  attrs: {
                    "show-arrows": false,
                    "show-arrows-on-hover": false,
                    "hide-delimiters": true,
                    cycle: "",
                    height: "64",
                  },
                },
                _vm._l(_vm.serviceFeatures, function (feature, i) {
                  return _c(
                    "v-carousel-item",
                    { key: i, attrs: { transition: "fade-transition" } },
                    [
                      _c("v-layout", [
                        _c(
                          "div",
                          { staticClass: "d-flex mx-auto" },
                          [
                            _c("v-img", {
                              attrs: {
                                width: "20",
                                height: "20",
                                src: require("../../assets/icons/serviceFeature/" +
                                  feature.pathImage),
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ml-3 service-feature-description align-self-center",
                              },
                              [_vm._v(" " + _vm._s(feature.description) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }